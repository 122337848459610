<template>
    <div class="admin-settings-trnaslations">
        <div class="row">
            <div class="col-12 mb-3 d-flex justify-content-end">
                <b-button variant="primary" @click="sysSettingModal = true">Add</b-button>
            </div>
            <div class="col-12">
                <GoodDataTable ref="dataTable1" class="w-100" mode="local" :no-serial="true" :columns="columns"
                    :load-local-data="1 === 1" />
            </div>
            <div class="col-12">
                <b-modal hide-footer v-model="sysSettingModal" centered title="System Setting">
                    <b-form @submit.prevent="saveSetting">
                        <div class="row">
                            <div class="col-12">
                                <b-form-group 
                                    id="setting_key-group" 
                                    label="Setting Key" 
                                >
                                    <b-form-input 
                                        id="setting_key" 
                                        v-model="form.key" 
                                        placeholder="Enter Key" 
                                        required
                                        :readonly="!isEmpty(editId)"
                                    />
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group 
                                    id="setting_value-group" 
                                    label="Setting Value" 
                                >
                                    <b-form-input 
                                        id="setting_value" 
                                        v-model="form.value" 
                                        placeholder="Enter Value" 
                                        required
                                    />
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group 
                                    id="setting_decription-group" 
                                    label="Setting Description" 
                                >
                                    <b-form-textarea 
                                        id="setting_decription" 
                                        v-model="form.decription" 
                                        placeholder="Enter Description" 
                                        rows="3"
                                    />
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-button variant="success" type="submit">Submit</b-button>
                            </div>
                        </div>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty, dateTimeFormat } from '@/utilities'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
export default {
    components: {
        GoodDataTable,
    },
    data: () => ({
        sysSettingModal: false,
        form: {},
        editId: null
    }),
    computed: {
        ...mapGetters('setting', [
            'sysSettings',
            'sysSettingByKey',
        ]),
        columns: {
            get() {
                return [
                    {
                        field: 'key',
                        label: 'Setting Key',
                    },
                    {
                        field: 'value',
                        label: 'Setting Value',
                    },
                    {
                        field: 'meta',
                        label: 'Meta',
                    },
                    {
                        field: 'decription',
                        label: 'Decription',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                action: props => {
                                    this.editSysSetting(props)
                                },
                            },
                        ],
                    },
                ]
            }
        },

    },
    methods: {

        ...mapActions('setting', [
            'getSystemSettings',
            'addSystemSettings',
            'updateSystemSettings',
        ]),

        isEmpty,

        async editSysSetting(set) {
            this.form = {
                key: set.key,
                value: set.value,
                decription: set.decription,
            }
            this.editId = set.id
            this.sysSettingModal = true
        },

        async saveSetting(){
            showLoader()
            if(!isEmpty(this.editId)){
                const params = {
                    ...this.form,
                    id: this.editId
                }
                await this.updateSystemSettings(params)
            }else{
                await this.addSystemSettings(this.form)
            }
            hideLoader()
            this.sysSettingModal = false
        },

        resetForm(){
            this.form = {
                key: null,
                value: null,
                decription: null,
            }
        }
    },

    mounted() {
        this.getSystemSettings()
        this.resetForm()
    },

    watch: {
        sysSettings: {
            handler(v) {
                this.$refs.dataTable1.setLocalData(v)
            }
        },
        sysSettingModal: {
            handler(v){
                if(v === false){
                    this.editId = null
                    this.resetForm()
                }
            }
        }
    }
}
</script>